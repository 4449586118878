import React, {useContext} from "react";
import {Button} from "react-bootstrap";
import {
  ClueMasterPageLayout,
  CLUE_MASTER_NAVIGATION_ACTIVE_KEYS,
  ListGroup, ListGroupItem, LoadingSpinner,
} from "../../components/components";
import useRefreshableRequest from "../../hooks/useRefreshableRequest";
import {getAllQueueData} from "../../services/firestore/functions/twilio";
import appContent from "../../markdown/app-content";
import {AppAlertsContext} from "../../context/app-alerts-context";

const CluePreviewPage = () => {
  const {popError} = useContext(AppAlertsContext);
  const {data, refreshData, isLoading} = useRefreshableRequest({
    request: getAllQueueData,
    onError: (e) => popError(e.message),
    defaultDataValue: [],
  });

  return <ClueMasterPageLayout activeID={CLUE_MASTER_NAVIGATION_ACTIVE_KEYS.messageQueue}>
    <LoadingSpinner isLoading={isLoading}>
      <div className="d-flex justify-content-between align-items-center">
        <h1>{appContent.cluemaster.messageQueue.pageTitle}</h1>
        <Button onClick={refreshData}>{appContent.cluemaster.messageQueue.refreshButtonText}</Button>
      </div>

      <ListGroup>
        {data.map(({friendlyName, currentSize, sid}) => <ListGroupItem key={sid} title={friendlyName}>
          {appContent.cluemaster.messageQueue.callerCount(currentSize)}
        </ListGroupItem>)}
      </ListGroup>
    </LoadingSpinner>
  </ClueMasterPageLayout>;
};

export default CluePreviewPage;
